<template>
  <div>
    <highcharts
      :constructor-type="'stockChart'"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>

<script>

export default {
  name: 'StockChart',
  data() {
    return {
      chartOptions: {
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            marker: {
              radius: 4,
              lineColor: '#666666',
              lineWidth: 1,
            },
          },
        },
        series: [
          {
            data: [
              [1523367000000, 173.25],
              [1523453400000, 172.44],
              [1523539800000, 174.14],
              [1523626200000, 174.73],
              [1523885400000, 175.82],
              [1523971800000, 178.24],
              [1524058200000, 177.84],
              [1524144600000, 172.8],
              [1524231000000, 165.72],
              [1524490200000, 165.24],
              [1524576600000, 162.94],
              [1524663000000, 163.65],
              [1524749400000, 164.22],
              [1524835800000, 162.32],
              [1525095000000, 165.26],
              [1525181400000, 169.1],
              [1525267800000, 176.57],
              [1525354200000, 176.89],
              [1525440600000, 183.83],
              [1525699800000, 185.16],
              [1525786200000, 186.05],
              [1525872600000, 187.36],
              [1525959000000, 190.04],
              [1526045400000, 188.59],
              [1526304600000, 188.15],
              [1526391000000, 186.44],
              [1526477400000, 188.18],
              [1526563800000, 186.99],
              [1526650200000, 186.31],
              [1526909400000, 187.63],
              [1526995800000, 187.16],
              [1527082200000, 188.36],
              [1527168600000, 188.15],
              [1527255000000, 188.58],
              [1527600600000, 187.9],
              [1527687000000, 187.5],
              [1527773400000, 186.87],
              [1527859800000, 190.24],
              [1528119000000, 191.83],
              [1528205400000, 193.31],
              [1528291800000, 193.98],
              [1528378200000, 193.46],
              [1528464600000, 191.7],
              [1528723800000, 191.23],
              [1528810200000, 192.28],
              [1528896600000, 190.7],
              [1528983000000, 190.8],
              [1529069400000, 188.84],
              [1529328600000, 188.74],
              [1529415000000, 185.69],
            ],
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.center-chart {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
