const AUTH_COOKIE_NAME = 'ath_dash';
const PUBLIC_ROUTES = ['Login', 'Landing', 'ResetPassword'];
const PROTECTED_ROUTES = ['Overall status',
  'Portfolios',
  'Profile',
  'Waterpoints',
  'Manage users and access',
  'Project Sites',
  'Environment and social safeguards',
  '!Tracker portfolio',
  'New and rehabilitated structures',
  'Photos and reports',
  'Waterpoint monitoring',
  'Disbursement and expenditures',
  'Data as a Service',
  'Process approval or reviews',
  'Compare',
  'Portfolio',
  'Holdings',
  'Summary',
  'News',
  'Quote',
  'CompanyProfile',
  'CompanyNews',
];
const QUOTE_OPTIONS = {
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 1.5,
  },
  xaxis: {
    tickPlacement: 'on',
    type: 'datetime',
    labels: {
      show: true,
      format: 'dd/MM',
    },
  },
  tooltip: {
    shared: true,
  },
  chart: {
    animations: {
      enabled: false,
    },
  },
  markers: {
    size: 0,
  },
};

export {
  AUTH_COOKIE_NAME,
  PUBLIC_ROUTES,
  PROTECTED_ROUTES,
  QUOTE_OPTIONS,
};
