<template>
  <div>
    <md-progress-bar md-mode="query"></md-progress-bar>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
};
</script>

<style lang="scss" scoped>
.md-progress-bar {
  margin: 24px;
}
</style>
