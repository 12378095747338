<template>
  <div>
    <p class="md-body-2" style="text-align:left;">
        <b>Project Implementation - Works</b>
        <br>This section aggregates beneficiary data from community engagement reporting (CER),
        on <b>Intermediate Result (IR) 1 (Development of multiple use water sources).</b>
        It tracks on <b>PDO level indicators</b> for the number of people provided with access
        to improved water sources, and the percentage of female beneficiaries
        with access.
        You can search and view implementation status results.
    </p>
      <md-table v-model='sites' md-sort='name' md-sort-order='asc' md-card md-fixed-header>
      <md-table-toolbar>
        <div class='md-toolbar-section-start'>
          <md-dialog :md-active.sync="showDialog">
            <md-dialog-title>Activities details</md-dialog-title>
              <md-tabs md-dynamic-height>
                <md-tab md-label="Component 1" md-icon="settings">
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Ullam mollitia dolorum dolores quae commodi impedit possimus qui,
                  atque at voluptates cupiditate. Neque quae culpa suscipit praesentium
                  inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                </md-tab>
                <md-tab md-label="Component 2" md-icon="language">
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Ullam mollitia dolorum dolores quae commodi impedit possimus qui,
                  atque at voluptates cupiditate. Neque quae culpa suscipit praesentium
                  inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                </md-tab>
                <md-tab md-label="Component 3" md-icon="room">
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                </md-tab>
              </md-tabs>
              <md-dialog-actions>
                <md-button class="md-primary" @click="showDialog = false">Close</md-button>
                <md-button class="md-primary" @click="showDialog = false">Save</md-button>
              </md-dialog-actions>
            </md-dialog>
          <md-button class="md-primary md-raised" @click="showDialog = false">PDO Indicators</md-button>
          <md-button class="md-primary md-raised" @click="showDialog = false">Beneficiaries</md-button>
        </div>
        <md-field md-clearable class='md-toolbar-section-end'>
          <md-input placeholder='Search record...' v-model='search' @input='searchOnTable' />
        </md-field>
      </md-table-toolbar>
      <md-table-empty-state
        md-label='No record found'
        :md-description='`No record found. Try a different search term or create a new record`'>
        <md-button class="md-primary md-raised" @click="showDialog = false">Add Record</md-button>
      </md-table-empty-state>
      <md-table-row slot='md-table-row' slot-scope='{ item }'>
        <md-table-cell md-label='Date' md-sort-by='date'>{{ item.code_key }}</md-table-cell>
        <md-table-cell md-label='State' md-sort-by='state'>{{ item.state_id }}</md-table-cell>
        <md-table-cell md-label='Site status'>{{ item.site_status }}</md-table-cell>
        <md-table-cell md-label='Implementation'>{{ item.implementation_type }}</md-table-cell>
        <md-table-cell md-label='Beneficiaries'>{{ item.beneficiaries }}</md-table-cell>
        <md-table-cell md-label='Actions'>
          <md-button class="md-icon-button md-raised md-dense md-primary">
            <md-icon>dehaze</md-icon>
          </md-button>
          <md-button class="md-icon-button md-raised md-dense md-primary">
            <md-icon>trending_up</md-icon>
          </md-button>
          <md-button class="md-icon-button md-raised md-dense md-primary">
            <md-icon>paid</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <p>{{ sites }}</p>
</div>
</template>

<script>
const toLower = (text) => text.toString().toLowerCase();

const searchByName = (items, term) => {
  if (term) {
    return items.filter((item) => toLower(item.name).includes(toLower(term)));
  }

  return items;
};

export default {
  name: 'PDOSearch',
  data: () => ({
    font: null,
    newSite: false,
    showDialog: false,
    search: null,
    searched: [],
    sites: [],
    loaded: false,
    paginatedUsers: [],
  }),
  // async mounted() {
  // },
  methods: {
    async getPDOData() {
      this.$store.commit('setLoading', true);
      this.sites = this.$store.getters.listPDOs;
      this.sites = await this.$store.dispatch('getPDOs');
      if (this.sites.length === 0) {
        this.sites = await this.$store.dispatch('getPDOs');
      }
      this.$store.commit('setLoading', false);
      this.loaded = true;
    },
    searchOnTable() {
      this.searched = searchByName(this.sites, this.search);
    },
  },
  created() {
    this.getPDOData();
    this.searched = this.sites;
  },
};
</script>

<style lang="scss" scoped>
.md-table + .md-table {
    margin-top: 16px
  }
.md-field {
    max-wdateth: 300px;
  }
  .md-table-head {
  text-align: center;
}
  .md-table-cell {
  text-align: left;
}
.container {
  display: flex;
  align-items: left;
}
</style>
