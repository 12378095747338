<template>
  <div>
    <p class="md-body-2" style="text-align:left;">
      <b>Project Management</b>
      <br>Data for new or rehabilitated waterpoints, is captured through Kobo forms,
      and is made available through API endpoints.
      <br>This interface allows you to search sites,
      and edit their details or suspend sites that have been dropped from the project,
      and are no longer being tracked
    </p>
    <md-table v-model='sites' md-sort='name' md-sort-order='asc' md-card md-fixed-header>
      <md-table-toolbar>
        <div class='md-toolbar-section-start'>
          <md-dialog :md-active.sync="showDialog" overflow:auto>
            <md-dialog-actions>
              <md-button class="md-primary" @click="showDialog = false">Close</md-button>
            </md-dialog-actions>
            <md-dialog-title>Implementation status</md-dialog-title>
            <md-dialog-content>
              <md-tabs md-dynamic-height>
                <md-tab md-label="Timeline" md-icon="timeline">
                  <div>
                    <stockchart />
                  </div>
                </md-tab>
                <md-tab md-label="Locations" md-icon="language">
                  <div>
                    <mapchart />
                  </div>
                </md-tab>
                <md-tab md-label="Summary" md-icon="leaderboard">
                  <div>
                    <!-- <BarChart /> -->
                  </div>
                  <div>
                    <chart />
                  </div>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  </p>
                </md-tab>
                <md-tab md-label="Gauge visual" md-icon="language">
                  <div>
                    <gaugechart />
                  </div>
                </md-tab>
                <md-tab md-label="Pie Charts" md-icon="language">
                  <div>
                    <piecharts />
                  </div>
                </md-tab>
                <md-tab md-label="Column Charts" md-icon="language">
                  <div>
                    <columnchart />
                  </div>
                </md-tab>
                <md-tab md-label="Pie Chart Status" md-icon="language">
                  <div>
                    <piechartstatus />
                  </div>
                </md-tab>
              </md-tabs>
            </md-dialog-content>
          </md-dialog>
          <md-button class="md-primary md-raised" @click="showDialog = true">Water status</md-button>
        </div>
        <md-field md-clearable class='md-toolbar-section-end'>
          <md-input placeholder='Search by Site name...' v-model='search' @input='searchOnTable' />
        </md-field>
      </md-table-toolbar>
      <md-table-row slot='md-table-row' slot-scope='{ item }'>
        <md-table-cell md-label="Date">{{ item.code_key }}</md-table-cell>
        <md-table-cell md-label="Implementation">{{ item.implementation_type }}</md-table-cell>
        <md-table-cell md-label="Site name">{{ item.site_name }}</md-table-cell>
        <md-table-cell md-label="Region">{{ item.region }}</md-table-cell>
        <md-table-cell md-label="District">{{ item.district }}</md-table-cell>
        <md-table-cell md-label="Village">{{ item.village }}</md-table-cell>
        <md-table-cell md-label="">
          <md-button class="md-icon-button md-raised md-dense md-primary" :to="`/sites/${item.code_key}/summary`">
            <md-icon>room</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
      <md-table-empty-state md-label='No project sites found'
        :md-description='`No site found. Try a different search term or refresh data`'>
        <md-button class="md-primary md-raised" @click="showDialog = false">Refresh Data</md-button>
      </md-table-empty-state>
    </md-table>
    <p>{{ sites }}</p>
  </div>
</template>

<script>

// import BarChart from './charts/BarChart.vue';
import BarChartSiteDetails from './charts/BarChartSiteDetails.vue';
import MapChartSiteDetails from './charts/MapChartSiteDetails.vue';
import GaugeChart from './charts/GaugeChart.vue';
import StockChart from './charts/StockChart.vue';
import PieCharts from './charts/PieCharts.vue';
import ColumnChart from './charts/ColumnChart.vue';
import PieChartStatus from './charts/PieChartsStatus.vue';

const toLower = (text) => text.toString().toLowerCase();

const searchByName = (items, term) => {
  if (term) {
    return items.filter((item) => toLower(item.name).includes(toLower(term)));
  }

  return items;
};

export default {
  name: 'SiteSearch',
  props: {
    partsdata: {
      type: Array,
    },
  },
  components: {
    // BarChart,
    chart: BarChartSiteDetails,
    mapchart: MapChartSiteDetails,
    gaugechart: GaugeChart,
    stockchart: StockChart,
    piecharts: PieCharts,
    columnchart: ColumnChart,
    piechartstatus: PieChartStatus,
  },
  data: () => ({
    font: null,
    newSite: false,
    showDialog: false,
    search: null,
    searched: [],
    sites: [],
    loaded: false,
    paginatedUsers: [],
  }),
  // async mounted() {
  // },
  methods: {
    async getSitesData() {
      this.$store.commit('setLoading', true);
      this.sites = this.$store.getters.listSites;
      this.sites = await this.$store.dispatch('getSites');
      if (this.sites.length === 0) {
        this.sites = await this.$store.dispatch('getSites');
      }
      this.$store.commit('setLoading', false);
      this.loaded = true;
    },
    searchOnTable() {
      this.searched = searchByName(this.sites, this.search);
    },
  },
  created() {
    this.getSitesData();
    this.searched = this.sites;
  },
};
</script>

<style lang="scss" scoped>
.md-table+.md-table {
  margin-top: 16px
}

.md-field {
  max-wstatusth: 300px;
}

.md-table-head {
  text-align: center;
}

.md-table-cell {
  text-align: left;
}

.container {
  display: flex;
  align-items: left;
}
</style>
