<template>
  <div id="landing" class="dp-bg1">
    <!-- https://fonts.google.com/icons?selected=Material+Icons
    source of icons "md-icon"="opacity"
    -->
    <md-tabs md-alignment="right">
      <md-tab id="tab-home" md-label="Home" md-icon="home"></md-tab>
      <md-tab id="tab-photos" md-label="Photo Gallery" md-icon="collections"></md-tab>
      <md-tab id="tab-login" to="/login" md-label="Login" md-icon="account_circle"></md-tab>
    </md-tabs>
    <md-empty-state
      class="md-primary"
      md-icon="opacity"
      md-label="Biyoole Project"
      md-description="Somalia Water for Agropastoral Productivity and Resilience (Biyoole)">
    </md-empty-state>
  </div>
</template>

<script>
export default {
  name: 'Landing',
};
</script>

<style scoped>
#landing {
  background-size: cover;
  min-height: 100vh;
}
</style>
