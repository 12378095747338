<template>
<div>

    <div>
      <p class="md-body-2" style="text-align:left;">Enter project site details:</p>
      <md-tabs class="md-primary" style="overflow-x: auto; margin-bottom: 15px;"
        :md-active-tab="activeTab"
        @md-changed="onTabChange">
        <md-tab id="tab-location" md-label="Location" md-icon="explore"></md-tab>
        <md-tab id="tab-water" md-label="Water Analysis" md-icon="invert_colors"> </md-tab>
        <md-tab id="tab-community-i" md-label="Community Investment" md-icon="restore"> </md-tab>
        <md-tab id="tab-community-e" md-label="Community Engagement" md-icon="perm_phone_msg"> </md-tab>
        <md-tab id="tab-construction-i" md-label="Construction Investment" md-icon="euro_symbol"> </md-tab>
        <md-tab id="tab-esmp" md-label="ESMP" md-icon="verified_user"> </md-tab>
        <md-tab id="tab-procure" md-label="Procurement" md-icon="edit_calendar"> </md-tab>
      </md-tabs>

    </div>
</div>
</template>

<script>
import moment from 'moment';
import { QUOTE_OPTIONS } from '../consts';
import { setQuoteSeries, setYAxis } from '../utils';

export default {
  name: 'Site',
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    symbols: {
      type: Array,
      default: () => ['GOOG', 'TSLA'],
    },
  },
  components: {

  },
  data() {
    return {
      period: '1d',
      interval: '5m',
      options: QUOTE_OPTIONS,
      series: [],
      loaded: true,
      activeTab: 'tab-location',
    };
  },
  methods: {
    onTabChange(tabId) {
      [, this.period] = tabId.split('-');
      switch (this.period) {
        case '1d':
          this.interval = '5m';
          break;
        case '5d':
          this.interval = '30m';
          break;
        case '1mo':
          this.interval = '1h';
          break;
        case '6mo':
          this.interval = '1d';
          break;
        case '1y':
          this.interval = '1d';
          break;
        case '5y':
          this.interval = '1wk';
          break;
        case 'max':
          this.interval = '1mo';
          break;
        default:
          this.period = '1d';
          this.interval = '5m';
      }
      this.compare();
    },
    searchQuote(event) {
      this.$router.push(`/quote/${event.symbol}/profile`);
    },
    toUppercase(str) {
      const newStr = str.toUpperCase();
      return newStr;
    },
    async compare() {
      if (this.symbols.length > 0) {
        this.loaded = true;
        await this.getQuoteHistory();
        this.options = {
          ...this.options,
          ...{
            xaxis: {
              type: 'datetime',
            },
            yaxis: setYAxis(this.series),
            legend: {
              position: 'top',
              horizontalAlign: 'left',
            },
            tooltip: {
              x: {
                formatter: function f(val) {
                  return moment(val).format('LLL');
                },
                tooltip: {
                  shared: true,
                },
              },
              y: {
                formatter: function f(val) {
                  return +val.toFixed(4);
                },
              },
            },
            chart: {
              animations: {
                enabled: false,
              },
              height: 'auto',
            },
          },
        };
        this.loaded = true;
      }
    },
    async getQuoteHistory() {
      const resp = await this.$store.dispatch('getStockHistoryData', {
        symbols: this.symbols.join(),
        interval: this.interval,
        period: this.period,
        include_info: false,
      });
      this.series = setQuoteSeries(resp.data);
      this.$store.commit('setLoading', false);
    },
    async delayedCompare() {
      await this.compare();
    },
  },
  watch: {
    symbols(val) {
      this.symbols = val;
      this.onTabChange('1d');
    },
  },
};
</script>

<style scoped>
.chart {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 800px;
}
</style>
