import Vue from 'vue';
import Vuex from 'vuex';
import * as api from '../api';
import { isValidJwt } from '../utils';
import { getCookie, setCookie, removeCookie } from '../utils/cookie';
import { AUTH_COOKIE_NAME } from '../consts';

Vue.use(Vuex);

// eslint-disable-next-line
// a single state tree -
// that is, this single object contains all your application level state
// and serves as the "single source of truth."
const getDefaultState = function defaultState() {
  return {
    // single source of data
    userData: JSON.parse(localStorage.getItem('_currentUser')) || {},
    portfolios: JSON.parse(localStorage.getItem('_portfolios')) || [],
    sites: JSON.parse(localStorage.getItem('_sites')) || {},
    PDOs: JSON.parse(localStorage.getItem('_PDOs')) || {},
    AllUsers: JSON.parse(localStorage.getItem('_AllUsers')) || {},
    currentPortfolio: JSON.parse(localStorage.getItem('_currentPortfolio')) || {},
    searchedSymbol: null,
    remember: false,
    loading: false,
    jwt: {
      access_token: getCookie(AUTH_COOKIE_NAME) || null,
    },
    shouldRefresh: false,
  };
};

// In our state dict, we are going to define our data,
// and their default values
const state = getDefaultState();

// asynchronous Vue Promises:
// Actions are functions that are used to 'commit' a mutation to a state
// to change the state or can be used to 'dispatch' i.e calls another action.
const actions = {
  getPDOs(context) {
    return api
      .getPDOs(state.jwt.access_token)
      .then((response) => {
        context.commit('setPDOs', { PDOs: response.data });
        return response.data;
      });
  },
  getAllUsers(context) {
    return api
      .getAllUsers(state.jwt.access_token)
      .then((response) => {
        context.commit('setAllUsers', { AllUsers: response.data });
        return response.data;
      });
  },
  getSites(context) {
    return api
      .getSites(state.jwt.access_token)
      .then((response) => {
        context.commit('setSites', { sites: response.data });
        return response.data;
      });
  },
  getPortfolios(context) {
    return api
      .getPortfolios(context.state.jwt.access_token)
      .then((response) => {
        context.commit('setPortfolios', { portfolios: response.data });
        return response.data;
      });
  },
  getPortfolio(context, identifier) {
    return api
      .getPortfolio(identifier, context.state.jwt.access_token)
      .then((response) => {
        context.commit('setPortfolio', { portfolio: response.data });
        return response.data;
      });
  },
  login(context, userData) {
    return api
      .login(userData.payload)
      .then((response) => {
        context.commit('resetState');
        context.commit('setJwtToken', { jwt: response.data });
        if (userData.remember) {
          setCookie(AUTH_COOKIE_NAME, state.jwt.access_token, '30d');
        } else {
          setCookie(AUTH_COOKIE_NAME, state.jwt.access_token);
        }
        state.loggedIn = true;
      })
      .catch(() => {
        context.commit('resetState');
        state.loggedIn = false;
      });
  },
  getCurrentUser(context) {
    return api
      .getUser(state.jwt.access_token)
      .then((response) => {
        context.commit('setUserData', { user: response.data });
        return response.data;
      });
  },
  logout(context) {
    return api
      .logout(context.state.jwt.access_token)
      .then(() => {
        context.commit('resetState');
      })
      .catch(() => {
        context.commit('resetState');
      }); // clear cookies even if it fails, then they will be logged out anyway
  },
  // register API promise
  register(context, userData) {
    return api
      .register(userData)
      .then(() => {
        Vue.toasted.show('Check your email to finish setting up your account.', { type: 'success' });
      });
  },
  resetPassword(context, payload) {
    return api
      .resetPassword(payload)
      .then(() => {
        Vue.toasted.show('Check your email to set up a new password', { type: 'success' });
      });
  },
  // setPassword API promise
  setPassword(context, payload) {
    return api
      .setPassword(payload)
      .then(() => {
        context.commit('resetState');
        Vue.toasted.show('You can now log in');
      });
  },
  submitNewPortfolio(context, portfolio) {
    return api
      .createNewPortfolio(portfolio, context.state.jwt.access_token)
      .then(() => {
        context.dispatch('successMessage');
      });
  },
  createNewHolding(context, data) {
    return api
      .createNewHolding(data, context.state.jwt.access_token)
      .then(() => {
        context.dispatch('successMessage');
      });
  },
  getStockHistoryData(context, params) {
    return api
      .getStockHistoryData(params, context.state.jwt.access_token);
  },
  search(context, params) {
    return api
      .search(params, context.state.jwt.access_token);
  },
  addSymbol(context, payload) {
    return api
      .addSymbol(payload, context.state.jwt.access_token)
      .then(() => {
        context.dispatch('getCurrentUser');
      });
  },
  resetState(context) {
    context.commit('resetState');
  },
  successMessage(context, message = 'Saved') {
    Vue.toasted.show(message, { type: 'success', duration: 1500 });
  },
  errorMessage(context, message = "Something's wrong") {
    Vue.toasted.show(message, { type: 'error', duration: 1500 });
  },
  getLatestStockPrices(context, params) {
    return api
      .getLatestStockPrices(params, context.state.jwt.access_token);
  },
  getNews(context, params) {
    return api
      .getNews(params, context.state.jwt.access_token);
  },
  getCompanyInfo(context, symbol) {
    return api
      .getCompanyInfo(symbol, context.state.jwt.access_token)
      .then((response) => response.data);
  },
  deleteSymbol(context, params) {
    return api
      .deleteSymbol(params, state.jwt.access_token)
      .then(() => {
        localStorage.clear();
        context.dispatch('successMessage', 'Deleted');
      });
  },
  deletePortfolio(context, params) {
    return api
      .deletePortfolio(params, state.jwt.access_token)
      .then(() => {
        localStorage.clear();
        context.dispatch('successMessage', 'Deleted');
      });
  },
  changePassword(context, payload) {
    return api
      .changePassword(payload, state.jwt.access_token)
      .then(() => {
        context.dispatch('successMessage', 'Password updated');
      });
  },
  updateUser(context, payload) {
    return api
      .updateUser(payload, state.jwt.access_token)
      .then((response) => {
        context.commit('setUserData', { user: response.data });
        context.dispatch('successMessage', 'User details updated');
        return response.data;
      });
  },
};

// Each mutation takes in the state and a value from the action committing it
const mutations = {
  // isolated data mutations
  setAllUsers(state, payload) {
    localStorage.setItem('_AllUsers', JSON.stringify(payload.AllUsers));
    state.AllUsers = payload.AllUsers;
  },
  setPDOs(state, payload) {
    localStorage.setItem('_PDOs', JSON.stringify(payload.PDOs));
    state.PDOs = payload.PDOs;
  },
  setSites(state, payload) {
    localStorage.setItem('_sites', JSON.stringify(payload.sites));
    state.sites = payload.sites;
  },
  setPortfolios(state, payload) {
    localStorage.setItem('_portfolios', JSON.stringify(payload.portfolios));
    state.portfolios = payload.portfolios;
  },
  setPortfolio(state, payload) {
    localStorage.setItem('_currentPortfolio', JSON.stringify(payload.portfolio));
    state.currentPortfolio = payload.portfolio;
  },
  setUserData(state, payload) {
    localStorage.setItem('_currentUser', JSON.stringify(payload.user));
    state.userData = payload.user;
  },
  setJwtToken(state, payload) {
    state.jwt = payload.jwt;
  },
  resetState(state) {
    localStorage.clear();
    removeCookie(AUTH_COOKIE_NAME);
    Object.assign(state, getDefaultState());
  },
  setLoading(state, bool) {
    state.loading = bool;
  },
};

// reusable data accessors:
// Getters are functions used to get the state
const getters = {
  isAuthenticated(state) {
    return isValidJwt(state.jwt.access_token);
  },
  getCurrentUser(state) {
    return state.userData;
  },
  hasPortfolio(state) {
    return state.portfolios.length > 0;
  },
  listPortfolios(state) {
    if (!state.portfolios.length > 0) {
      return [];
    }
    return state.portfolios;
  },
  listAllUsers(state) {
    if (!state.AllUsers.length > 0) {
      return [];
    }
    return state.AllUsers;
  },
  listPDOs(state) {
    if (!state.PDOs.length > 0) {
      return [];
    }
    return state.PDOs;
  },
  listSites(state) {
    if (!state.sites.length > 0) {
      return [];
    }
    return state.sites;
  },
  currentPortfolio(state) {
    return state.currentPortfolio;
  },
  hasHoldings(state) {
    const { holdings } = state.currentPortfolio;
    return holdings && holdings.length > 0;
  },
  searchedSymbol(state) {
    return state.searchedSymbol;
  },
  isLoading(state) {
    return state.loading;
  },
};

// create store
const store = new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
});

export default store;
