import Vue from 'vue';
import Router from 'vue-router';

import Dashboard from '../components/Dashboard.vue';
import NotFound from '../components/NotFound.vue';
import Landing from '../components/Landing.vue';
import Login from '../components/Login.vue';
import ResetPassword from '../components/ResetPassword.vue';
import WaterCosts from '../components/WaterCosts.vue';
import Portfolio from '../components/Portfolio.vue';
import News from '../components/portfolio/News.vue';
import Quote from '../components/Quote.vue';
import Site from '../components/Site.vue';
import Profile from '../components/Profile.vue';
import ManageUsers from '../components/ManageUsers.vue';
import NewSites from '../components/NewSites.vue';
import Reports from '../components/Reports.vue';
import Activities from '../components/Activities.vue';
import Expenditures from '../components/Expenditures.vue';
import CompanyProfile from '../components/portfolio/CompanyProfile.vue';
import BiyooleMIS from '../components/BiyooleMIS.vue';
import PendingActions from '../components/PendingActions.vue';
import SafeguardCompliance from '../components/SafeguardCompliance.vue';
import Register from '../components/Register.vue';
import Grievances from '../components/Grievances.vue';
import store from '../store';
import { PUBLIC_ROUTES, PROTECTED_ROUTES } from '../consts';

// add Router to Vue as a plugin
Vue.use(Router);

// create a router object that will hold all routes
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: {
        path: '/landing',
      },
    },
    {
      path: '/landing',
      name: 'Landing',
      component: Landing,
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
    },
    {
      path: '/dashboard',
      name: 'Overall status',
      component: Dashboard,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/users',
      name: 'Manage users and access',
      component: ManageUsers,
    },
    {
      path: '/sites',
      name: 'Project Sites',
      component: NewSites,
    },
    {
      path: '/expenditures',
      name: 'Disbursement and expenditures',
      component: Expenditures,
    },
    {
      path: '/biyoolemis',
      name: 'Data as a Service',
      component: BiyooleMIS,
    },
    {
      path: '/actions',
      name: 'Process approval or reviews',
      component: PendingActions,
    },
    {
      path: '/activities',
      name: 'Waterpoint monitoring',
      component: Activities,
    },
    {
      path: '/reports',
      name: 'Photos and reports',
      component: Reports,
    },
    {
      path: '/safeguards',
      name: 'Environment and social safeguards',
      component: SafeguardCompliance,
    },
    {
      path: '/grievances',
      name: '!Tracker portfolio',
      component: Grievances,
    },
    {
      path: '/portfolios/:portfolioId',
      name: 'Portfolio',
      component: Portfolio,
    },
    {
      path: '/quote/:quote',
      name: 'Quote',
      component: Quote,
      children: [
        {
          name: 'CompanyProfile',
          path: 'profile',
          component: CompanyProfile,
        },
        {
          name: 'CompanyNews',
          path: 'news',
          component: News,
        },
      ],
    },
    {
      path: '/costs',
      name: 'New and rehabilitated structures',
      component: WaterCosts,
    },
    {
      path: '/site',
      name: 'Waterpoints',
      component: Site,
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
    },
    {
      path: '/reset/:passwordToken',
      name: 'ResetPassword',
      component: ResetPassword,
    },
    {
      path: '*',
      component: NotFound,
    },
  ],
});
// set global navigation guards
router.beforeEach((to, from, next) => {
  // if the user is just coming to the site redirect to /landing

  // if user is not yet authenticated (not logged in)
  if (!store.getters.isAuthenticated) {
    // if this unauthenticated user tries to open a protected URL (e.g dashboard)
    if (PROTECTED_ROUTES.includes(to.name)) {
      // redirect the user to the login URL
      next('/login');
    } else {
      next();
    }
  } else if (PUBLIC_ROUTES.includes(to.name)) {
  // but if the user is auntenticated (logged in)
  // and tries to open a public URL (e.g login, register)
  // redirect to the dashboard URL
    next('/dashboard');
  } else {
  // in any other cases, send the user to the path requested in the URL
    next();
  }
});
// export the object so that the rest of the
// application can access it
export default router;
