<template>
  <div>
    <!-- https://fonts.google.com/icons?selected=Material+Icons -->
    <md-empty-state
      md-icon="error_outline"
      md-label="Error 404 - Not Found"
      md-description="Requested page could not be found."
    >
      <router-link to="/dashboard">Click here to Go Back</router-link>
    </md-empty-state>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
