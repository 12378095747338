<template>
  <div>
    <md-autocomplete
      class="search-box"
      v-model="search"
      :md-options="getFilteredSites"
      @input="getTickers"
      @md-selected="onSelect"
      :md-layout="searchLayout">

      <label>{{ this.placeholder }}</label>
      <template slot="md-autocomplete-item" slot-scope="{ item, term }">
        <md-highlight-text :md-term="term">{{ item.Symbol }} - {{ item.Name }}</md-highlight-text>
      </template>
    </md-autocomplete>

    <md-toolbar class="md-primary">
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start">
          <md-button class="md-icon-button">
            <md-icon>menu</md-icon>
          </md-button>
        </div>

        <md-autocomplete
          class="search"
          v-model="selectedSites"
          :md-options="sites"
          md-layout="box">
          <label>Search By State...</label>
        </md-autocomplete>

        <div class="md-toolbar-section-end">
          <md-button class="md-icon-button">
            <md-icon>refresh</md-icon>
          </md-button>

          <md-button class="md-icon-button">
            <md-icon>more_vert</md-icon>
          </md-button>
        </div>
      </div>
    </md-toolbar>

    <div>

    </div>
  </div>

</template>

<script>
export default {
  name: 'Search',
  props: {
    searchLayout: {
      type: String,
      default: 'box',
    },
    placeholder: {
      type: String,
      default: 'Search By Name or Code...',
    },
  },
  data: () => ({
    search: '',
    tickers: [],
    value: '',
    selectedSites: null,
    sites: [
      'Galmudug Sites',
      'Puntland Sites',
      'South West State Sites',
    ],
  }),
  methods: {
    async getTickers(q) {
      this.$emit('input', this.selectedLabel);
      this.$store.dispatch('search', { q }).then((resp) => {
        const results = [];
        for (let i = 1; i < resp.data.length + 1; i += 1) {
          results.push({
            id: i,
            symbol: resp.data[i - 1].symbol,
            name: resp.data[i - 1].name,
          });
        }
        this.tickers = results;
      });
    },
    onSelect(ticker) {
      this.symbol = ticker.Symbol;
      this.$emit('search', { symbol: ticker.Symbol, short_name: ticker.Name });
    },
  },
  computed: {
    getFilteredSites() {
      return this.sites.filter((site) => ({
        Id: site.id,
        Name: site.name,

      }));
    },
  },
};
</script>

<style scoped>
.search-box {
  width: 100%;
  max-width: 100%;
}
</style>
