<template>
  <div>
    <!-- Click Change Value, and then Change Y Axis Max. Value should be 200 -->
    <highcharts :options='chartOptions' ref='myGauge'></highcharts>
    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia
       dolorum Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam
        mollitia dolorum</p>
    <!-- <button @click='changeValue'>Change Value</button>
    <button @click='changeAxis'>Change Y Axis Max</button> -->
  </div>
</template>

<script>

export default {
  name: 'GaugeChart',
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'solidgauge',
          styledMode: false,
          margin: 0,
        },
        pane: {
          center: ['50%', '50%'],
          size: '95%',
          startAngle: -90,
          endAngle: 90,
          background: [
            {
              backgroundColor: '#eee',
              innerRadius: '100%',
              outerRadius: '100%',
              shape: 'arc',
              borderWidth: 10,
            },
          ],
        },
        plotOptions: {
          solidgauge: {
            lineWidth: 10,
            radius: '90%',
            innerRadius: '100%',
            dataLabels: {
              borderWidth: 0,
              y: -70,
              useHTML: true,
            },
            series: {
              color: 'red',
            },
          },
        },
        exporting: { enabled: true },
        tooltip: { enabled: false },
        credits: { enabled: false },
        title: null,
        yAxis: {
          min: 0,
          max: 1000,
          lineWidth: 0,
          minorTickInterval: null,
          tickWidth: 0,
          title: null,
          labels: {
            enabled: true,
          },
        },
        series: [
          {
            data: [500],
            dataLabels: {
              // eslint-disable-next-line
              format: `<h1>\${y}</h1><h6 class='AutoCalc-chartLabel'>Total Expenditure</h6>`,
            },
            color: 'red',
          },
        ],
      },
    };
  },
  methods: {
    changeValue() {
      this.$refs.myGauge.chart.series[0].data[0].update(200);
    },
    changeAxis() {
      this.chartOptions.yAxis.max = 4000;
    },
  },
};
</script>

<style scoped>
.center-chart {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
