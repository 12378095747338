<template>
  <div>
    <p class="md-body-2" style="text-align:left;">
        <b>Biyoole web app</b>
        <br>This is a complementary web application built
        using the <b>Sahamiye</b>
        data as a service (DaaS) platform. Sahamiye DaaS
         is a story of adaptation and resilience.
          In 2020, with COVID-19
          movement restrictions imposed inside Somalia, an M&E team based
           in Mogadishu
         and Baidoa were stuck in never-ending lockdowns. We had to do our
          jobs, and so we started
          slowly building
        a data-as-a-service tool to allow remote M&E teams to work without physical
         barriers. We called this tool <b>Sahamiye</b>, a Somali phrase from the
         traditional concept of SAHAN, that
         loosely translates
        to a <b>Scout</b> or a <b>Surveyor</b>.
    </p>
<div>
    <md-card md-with-hover>
      <md-card-header>
        <md-avatar>
          <img src="../assets/covid.jpg" alt="Covid">
        </md-avatar>

        <div class="md-title">Enter COVID-19 restrictions</div>
        <div class="md-subhead">M&E teams physically cut-off</div>
      </md-card-header>

      <md-card-media>
        <img src="../assets/covid.jpg" alt="Covid">
      </md-card-media>

      <md-card-content>
        As the COVID
         season raged havoc in the streets,
        life on the Internet did not stop. When offices were deserted,
         virtual work spaces took over with 3G networks
        overflowing with immense traffic. M&E work, as we know it,
         had basically
         moved online; a new digital space had been
        created.
      </md-card-content>
    </md-card>
    <md-card md-with-hover>
      <md-card-header>
        <md-avatar>
          <img src="../assets/camel.jpg" alt="Sahan">
        </md-avatar>

        <div class="md-title">Inspired by the Somali Sahan.</div>
        <div class="md-subhead">Sending out knowledge-scouts</div>
      </md-card-header>

      <md-card-media>
        <img src="../assets/camel.jpg" alt="Sahan">
      </md-card-media>

      <md-card-content>
        In times of drought, a group of knowledge-scouts<b>(Sahamiyaals)</b> were sent out
        to seek pasture and water opportunities
          for a community's survival. The idea of creating a digital
           nomadic-platform with a SAHAN modus operandi was considered when COVID-19
            restrictions came.
      </md-card-content>
    </md-card>
    <md-card md-with-hover>
      <md-card-header>
        <md-avatar>
          <img src="../assets/sahamiye.jpg" alt="Sahamiye">
        </md-avatar>

        <div class="md-title">Sahamiye Platform is born</div>
        <div class="md-subhead">A digital M&E nomad</div>
      </md-card-header>

      <md-card-media>
        <img src="../assets/sahamiye.jpg" alt="Sahamiye">
      </md-card-media>

      <md-card-content>
        Today, we continue refining the <b>Sahamiye</b> M&E product
        by combining web-based open source frameworks such as VueJS, Node,
        and Flask, to build backend API frameworks that run on
        PostgreSQL or MongoDB, for remote
        M&E teams to take advantage of this new
        digital space.

      </md-card-content>
    </md-card>
  </div>
</div>
</template>

<style lang="scss" scoped>
  .md-card {
    width: 320px;
    margin: 4px;
    display: inline-block;
    vertical-align: top;
  }
</style>

<script>
export default {
  name: 'RegularCards',
};
</script>
