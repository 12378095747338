<template>
  <div>
    <p class="md-body-2" style="text-align:left;">
        <b>Project Evidence</b>
        <br>As proof of completion of a process, community reports and photos
        are uploaded into different Kobo M&E forms. These reports and pictures will be made
         available in this section.
        You can search for reports or photos, and download for offline use. Some photos will
        be displayed in the public-facing photo gallery.
    </p>
      <md-table v-model='searched' md-sort='name' md-sort-order='asc' md-card md-fixed-header>
      <md-table-toolbar>
        <div class='md-toolbar-section-start'>
          <md-dialog :md-active.sync="showDialog">
            <md-dialog-title>Upload Reports</md-dialog-title>
              <md-tabs md-dynamic-height>
                <md-tab md-label="Photos" md-icon="add_photo_alternate">
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Ullam mollitia dolorum dolores quae commodi impedit possimus qui,
                  atque at voluptates cupiditate. Neque quae culpa suscipit praesentium
                  inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                </md-tab>
                <md-tab md-label="Reports" md-icon="picture_as_pdf">
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Ullam mollitia dolorum dolores quae commodi impedit possimus qui,
                  atque at voluptates cupiditate. Neque quae culpa suscipit praesentium
                  inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                </md-tab>
              </md-tabs>
              <md-dialog-actions>
                <md-button class="md-primary" @click="showDialog = false">Close</md-button>
                <md-button class="md-primary" @click="showDialog = false">Save</md-button>
              </md-dialog-actions>
            </md-dialog>
          <md-button class="md-primary md-raised" @click="showDialog = true">Photos</md-button>
          <md-button class="md-primary md-raised" @click="showDialog = fasle">Reports</md-button>
        </div>
        <md-field md-clearable class='md-toolbar-section-end'>
          <md-input placeholder='Search by First name...' v-model='search' @input='searchOnTable' />
        </md-field>
      </md-table-toolbar>
      <md-table-empty-state
        md-label='No reports found'
        :md-description='`No user found. Try a different search term or create a new user`'>
        <md-button class="md-primary md-raised" @click="showDialog = true">Add a Report</md-button>
      </md-table-empty-state>
      <md-table-row slot='md-table-row' slot-scope='{ item }'>
        <md-table-cell md-label='Submission date' md-sort-by='date'>{{ item.date }}</md-table-cell>
        <md-table-cell md-label='Submitted by' md-sort-by='submitted_by'>{{ item.submitted_by }}</md-table-cell>
        <md-table-cell md-label='Site' md-sort-by='site'>{{ item.site }}</md-table-cell>
        <md-table-cell md-label='Report name' md-sort-by='name'>{{ item.name }}</md-table-cell>
        <md-table-cell md-label='Report type' md-sort-by='report_type'>{{ item.report_type }}</md-table-cell>
        <md-table-cell md-label='Actions'>
          <md-button class="md-icon-button md-raised md-dense md-primary">
            <md-icon>arrow_circle_down</md-icon>
          </md-button>
          <md-button class="md-icon-button md-raised md-dense md-primary">
            <md-icon>outgoing_mail</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
</div>
</template>

<script>
const toLower = (text) => text.toString().toLowerCase();

const searchByName = (items, term) => {
  if (term) {
    return items.filter((item) => toLower(item.name).includes(toLower(term)));
  }

  return items;
};

export default {
  name: 'TableSearch',
  data: () => ({
    search: null,
    showDialog: false,
    searched: [],
    reports: [],
  }),
  methods: {
    searchOnTable() {
      this.searched = searchByName(this.reports, this.search);
    },
  },
  created() {
    this.searched = this.reports;
  },
};
</script>

<style lang="scss" scoped>
.md-table + .md-table {
    margin-top: 16px
  }
.md-field {
    max-wdateth: 300px;
  }
  .md-table-head {
  text-align: center;
}
  .md-table-cell {
  text-align: left;
}
.container {
  display: flex;
  align-items: left;
}
</style>
