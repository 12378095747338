<template>
  <div>
    <div>
      <highcharts :options="pieChartOptions" ref="pieChart"></highcharts>
    </div>
    <div>
      <highcharts :options="pieChartOptions2" ref="pieChart2"></highcharts>
    </div>
    <div>
      <highcharts :options="pieChartOptions3" ref="pieChart3"></highcharts>
    </div>
    <div>
      <highcharts :options="columnChartOptions" ref="columnChart"></highcharts>
    </div>
    <div>
      <highcharts :options="columnChartOptions1" ref="columnChart1"></highcharts>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columnChartOptions1: {
        chart: {
          type: 'column',
        },
        title: {
          text: 'Provider Types',
        },
        subtitle: {
          text: 'All,VA and Community Users',
        },
        xAxis: {
          categories: [
            'Psychologist',
            'Social Worker',
            'Psychiatrist',
            'Trainee',
            'Licensed Counselor',
            'Nursing',
            'Family Therapist (MFT)',
            'Unknown',
            'Other*',
          ],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Num of Providers',
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            // eslint-disable-next-line
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: 'ALL Users',
            data: [156, 69, 25, 15, 11, 11, 9, 12, 35],
          },
          {
            name: 'VA Users',
            data: [128, 57, 20, 10, 4, 7, 1, 3, 17],
          },
          {
            name: 'Community Users',
            data: [28, 12, 5, 5, 7, 4, 8, 9, 18],
          },
        ],
      },
      columnChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          text: 'Referral Categories',
        },
        subtitle: {
          text: 'VA and Community',
        },
        xAxis: {
          categories: [
            'Resources',
            'Program Development',
            'Medication',
            'Tx/Therapy',
            'Program Referral',
            'Dx/Screening/Assessment',
            'Mentoring',
            'Disaster Relief',
            'Other',
          ],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Num of Categories',
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
          // eslint-disable-next-line
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: 'VA',
            data: [176, 62, 19, 137, 68, 55, 19, 3, 14],
          },
          {
            name: 'Community',
            data: [76, 19, 5, 45, 2, 44, 0, 21, 10],
          },
        ],
      },
      pieChartOptions: {
        chart: { type: 'pie', options3d: { enabled: true, alpha: 45 } },
        title: { text: 'Provider Context' },
        subtitle: { text: 'Hover over sections for Provider Context data' },
        credits: { enabled: false },
        plotOptions: {
          pie: { innerSize: 100, depth: 45 },
          series: { allowPointSelect: true },
        },
        series: [
          {
            data: [
              { name: 'VA', y: 441 },
              { name: 'Non-Governmental', y: 53 },
              { name: 'Private Practice', y: 39 },
              { name: 'DoD', y: 15 },
              { name: 'Vet Center', y: 12 },
              { name: 'State & Local Govt', y: 6 },
              { name: 'Other Fed Agency', y: 3 },
            ],
          },
        ],
      },
      pieChartOptions2: {
        chart: { type: 'pie', options3d: { enabled: true, alpha: 45 } },
        title: { text: 'Clinic Setting' },
        subtitle: { text: 'Hover over sections for Clinic Setting data' },
        credits: { enabled: false },
        plotOptions: {
          pie: { innerSize: 100, depth: 45 },
          series: { allowPointSelect: true },
        },
        series: [
          {
            data: [
              { name: 'PTSD Clinical team/Outpatient', y: 161 },
              { name: 'Mental Health Clinic/BHIP', y: 81 },
              { name: 'CBOC', y: 58 },
              { name: 'SPC', y: 22 },
              { name: 'Residential', y: 18 },
              { name: 'Other', y: 6 },
              { name: 'Substance Use Program', y: 14 },
              { name: 'Unknown', y: 13 },
            ],
            dataLabels: {
              // eslint-disable-next-line
              formatter: function () {
                // console.log("this.point is: ", this.point);
                // eslint-disable-next-line
                return this.point.name + ':<br/>' + '(' + this.y + ')';
                // return `${this.point.name}:<br/>(${this.y})`
              },
            },
          },
        ],
      },
      pieChartOptions3: {
        chart: { type: 'pie', options3d: { enabled: true, alpha: 45 } },
        title: { text: 'Provider Types' },
        subtitle: { text: 'Hover over sections for Provider Types data' },
        credits: { enabled: false },
        plotOptions: {
          pie: { innerSize: 100, depth: 45 },
          series: { allowPointSelect: true },
        },
        series: [
          {
            data: [
              { name: 'Psychologist', y: 293 },
              { name: 'Social Worker', y: 148 },
              { name: 'Psychiatrist', y: 36 },
              { name: 'Licensed Counselor', y: 19 },
              { name: 'Trainee', y: 19 },
              { name: 'Family Therapist', y: 10 },
              { name: 'Administrator', y: 8 },
              { name: 'Nurse', y: 6 },
            ],
          },
        ],
      },
    };
  },
};
</script>
