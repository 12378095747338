<template>
  <div>
    <p class="md-body-2" style="text-align:left;">
        Users are registered into the system and
        assigned different access roles.
        <br>The designate M&E officer receives user's details and
        adds them by sending a verification email which contains a link with instructions.
    </p>
      <md-table v-model='users' md-sort='name' md-sort-order='asc' md-card md-fixed-header>
      <md-table-toolbar>
        <div class='md-toolbar-section-start'>
          <md-dialog :md-active.sync="showDialog">
            <md-dialog-title>Registration</md-dialog-title>
              <md-tabs md-dynamic-height>
                <md-tab md-label="Add User" md-icon="perm_identity">
                  <!-- register user form -->
                  <div>
                    <form novalidate @submit.prevent="onSubmit">
                      <md-field>
                        <label for="fistName">First name</label>
                        <md-input v-model="firstName" name="firstName" id="firstName" autofocus></md-input>
                      </md-field>
                      <p class="dp-error" v-if="msg.firstName">Must have at least two characters</p>
                      <md-field>
                        <label for="lastName">Last name</label>
                        <md-input name="lastName" id="lastName"></md-input>
                      </md-field>
                      <p class="dp-error" v-if="msg.lastName">Must have at least two characters</p>
                      <md-field>
                        <label for="email">Email</label>
                        <md-input name="email" id="email" autocomplete="off"></md-input>
                      </md-field>
                      <p class="dp-error" v-if="msg.email">Invalid email address</p>
                      <md-checkbox class="md-primary" v-model="isStaff" id="isStaff"
                       value="isStaff">Is Staff?</md-checkbox>
                       <br>
                      <md-button class="md-raised md-primary" type="submit"
                       :disabled="this.$store.getters.isLoading">Add</md-button>
                    </form>
                  </div>
                </md-tab>
              </md-tabs>
              <md-dialog-actions>
                <md-button class="md-primary" @click="showDialog = false">Cancel</md-button>
              </md-dialog-actions>
            </md-dialog>
          <md-button class="md-primary md-raised" @click="showDialog = true">Add New User</md-button>
        </div>
        <md-field md-clearable class='md-toolbar-section-end'>
          <md-input placeholder='Search by First name...' v-model='search' @input='searchOnTable' />
        </md-field>
      </md-table-toolbar>
      <md-table-empty-state
        md-label='No users found'
        :md-description='`No user found. Try a different search term or create a new user`'>
        <md-button class="md-primary md-raised" @click="showDialog = true">Add New User</md-button>
      </md-table-empty-state>
      <md-table-row slot='md-table-row' slot-scope='{ item }'>
        <md-table-cell md-label='Access ID'>{{ item.id }}</md-table-cell>
        <md-table-cell md-label='Fist name' md-sort-by='name'>{{ item.name }}</md-table-cell>
        <md-table-cell md-label='Last name' md-sort-by='last_name'>{{ item.last_name }}</md-table-cell>
        <md-table-cell md-label='Email' md-sort-by='email'>{{ item.email }}</md-table-cell>
        <md-table-cell md-label='Staff?' md-sort-by='staff'>{{ item.staff }}</md-table-cell>
        <md-table-cell md-label='Actions'>
          <md-button class="md-icon-button md-raised md-dense md-primary">
            <md-icon>dehaze</md-icon>
          </md-button>
          <md-button class="md-icon-button md-raised md-dense md-primary">
            <md-icon>outgoing_mail</md-icon>
          </md-button>
          <md-button class="md-icon-button md-raised md-dense md-primary">
            <md-icon>visibility_off</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <p>{{ sites }}</p>
</div>
</template>

<script>
import { isValidEmail } from '../utils';

const toLower = (text) => text.toString().toLowerCase();

const searchByName = (items, term) => {
  if (term) {
    return items.filter((item) => toLower(item.name).includes(toLower(term)));
  }

  return items;
};

export default {
  name: 'TableSearch',
  data: () => ({
    search: null,
    isStaff: null,
    showDialog: false,
    firstClick: true,
    email: '',
    firstName: '',
    lastName: '',
    msg: {
      firstName: false,
      lastName: false,
      email: false,
    },
    searched: [],
    users: [],
    sites: [],
  }),
  watch: {
    email: {
      handler: function email(value) {
        // binding this to the data value in the email input
        this.email = value;
        this.msg.email = !isValidEmail(value);
      },
    },
    firstName: {
      handler: function firstName(value) {
        this.firstName = value;
        this.msg.firstName = !this.validName(value);
      },
    },
    lastName: {
      handler: function lastName(value) {
        this.lastName = value;
        this.msg.lastName = !this.validName(value);
      },
    },
  },
  methods: {
    async getUsersData() {
      this.$store.commit('setLoading', true);
      this.sites = this.$store.getters.listAllUsers;
      this.sites = await this.$store.dispatch('getAllUsers');
      if (this.sites.length === 0) {
        this.sites = await this.$store.dispatch('getAllUsers');
      }
      this.$store.commit('setLoading', false);
      this.loaded = true;
    },
    validName(value) {
      return value.length > 1;
    },
    async save() {
      // callout to login user
      this.$store.commit('setLoading', true);
      await this.$store.dispatch('register', {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        isStaff: this.isStaff,
      });
      this.$store.commit('setLoading', false);
    },
    onSubmit() {
      // capture the boolean value from 'msg.email',
      // 'msg.firstName' and 'msg.lastName' entries
      const valid = !this.msg.email && !this.msg.firstName && !this.msg.lastName;

      // if valid=true
      if (valid) {
        // but what if all the text fields are empty?
        if (this.firstName === '' && this.lastName === '' && this.email === '') {
          // set all boolean values of 'msg' data to true
          this.msg.firstName = true;
          this.msg.lastName = true;
          this.msg.email = true;
        } else {
          this.save();
        }
      }
    },
    searchOnTable() {
      this.searched = searchByName(this.users, this.search);
    },
  },
  created() {
    this.getUsersData();
    this.searched = this.users;
  },
};
</script>

<style lang="scss" scoped>
.md-table + .md-table {
    margin-top: 16px
  }
.md-field {
    max-width: 300px;
  }
  .md-table-head {
  text-align: center;
}
  .md-table-cell {
  text-align: left;
}
.container {
  display: flex;
  align-items: left;
}
.md-dialog {
    max-width: 400px;
  }
</style>
