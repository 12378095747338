<template>
  <div>
    <md-app>
      <!-- API md-app
      https://www.creative-tim.com/vuematerial/components/app
      It contains all the behaviours needed to build complex
      application shells, that will be simple,
      responsive and it will integrate seamlessly with drawers,
      toolbars and content.
      The md-app component creates 3 distinct components:
      - md-app-toolbar,
      - md-app-drawer and
      - md-app-content, which are aliases for md-toolbar, md-drawer
      and md-content respectively
      -->
      <md-app-toolbar class="md-primary">
        <!-- md-app-toolbar:
        Creates the toolbar accepting any options from the md-toolbar
        -->
        <span class="md-title">{{ title }}</span>
      </md-app-toolbar>

      <md-app-drawer :md-active.sync="menuVisible" md-permanent="full">
        <!-- md-app-drawer: create a navigation drawer panel
        https://www.creative-tim.com/vuematerial/components/drawer
        Places the drawer panel, that also accepts any options from the md-drawer.
        You can have a left or right placed drawer.

        Use a persistent mini drawer:
        Persistent navigation drawers can toggle open or closed.
        The drawer sits on the same surface elevation as the content.
        It is closed by default and opens by selecting the menu icon,
        and stays open until closed by the user.
        Persistent drawers come with two sub-types: Full and Mini:
        Mini is great to maximize the content area, without
        losing the navigation buttons
        -->
        <md-toolbar class="md-transparent" md-elevation="0">
          <!-- API - md-toolbar
          class "md-transparent" makes the toolbar background transparent
          -->
          <span flex><b>The Biyoole Project</b></span>
          <div class="md-toolbar-section-end">
          <!-- class "md-toolbar-section-start" makes the md-button
          to be left-aligned and
          'md-toolbar-section-end' right-aligned
          -->
            <!-- API - md-button
            class "md-icon-button" enables the rounded-shape icon button,
            "md-dense" is an alias for "md-mini"

            https://materialdesignicons.com/

            -->
          </div>
        </md-toolbar> <!-- end md-toolbar -->

        <md-list class="md-dense">
          <!-- API - md-list:
          menu lists with icons
          Single line lists would be really great for navigational elements, such as drawers.

          API - md-list-item:

          -->
          <md-menu :md-offset-x="200" :md-offset-y="-110">
            <md-list-item @click="toggleSubmenu" md-menu-trigger>
              <span class="md-list-item-text">
                {{ this.$store.getters.getCurrentUser.email || 'Logged In' }}
              </span>
              <md-icon>keyboard_arrow_right</md-icon>
            </md-list-item>
            <md-menu-content>
              <md-menu-item to="/profile" @click="toggleMenu">Profile</md-menu-item>
              <md-menu-item @click="logout">Logout</md-menu-item>
            </md-menu-content>
          </md-menu>
          <md-list-item to="/dashboard" @click="toggleMenu">
            <md-icon class="md-primary">dashboard</md-icon>
            <span class="md-list-item-text">Overall dashboard</span>
          </md-list-item>
          <md-list-item to="/users" @click="toggleMenu">
            <md-icon class="md-primary">manage_accounts</md-icon>
            <span class="md-list-item-text">Manage users</span>
          </md-list-item>
          <md-list-item to="/actions" @click="toggleMenu">
            <md-icon class="md-primary">fact_check</md-icon>
            <span class="md-list-item-text">Pending Actions</span>
          </md-list-item>
          <md-divider></md-divider>
          <md-subheader>Component I</md-subheader>
          <md-divider class="md-inset"></md-divider>
          <md-list-item to="/sites" @click="toggleMenu">
            <md-icon class="md-primary">list</md-icon>
            <span class="md-list-item-text">Manage sites</span>
          </md-list-item>
          <md-list-item to="/activities" @click="toggleMenu">
            <md-icon class="md-primary">front_loader</md-icon>
            <span class="md-list-item-text">PDO indicators</span>
          </md-list-item>
          <md-list-item to="/reports" @click="toggleMenu">
            <md-icon class="md-primary">image</md-icon>
            <span class="md-list-item-text">Manage reports</span>
          </md-list-item>
          <md-list-item to="/expenditures" @click="toggleMenu">
            <md-icon class="md-primary">paid</md-icon>
            <span class="md-list-item-text">Track budgets</span>
          </md-list-item>
          <md-list-item to="/costs" @click="toggleMenu">
            <md-icon class="md-primary">opacity</md-icon>
            <span class="md-list-item-text">Water costs</span>
          </md-list-item>
          <md-list-item to="/safeguards" @click="toggleMenu">
            <md-icon class="md-primary">offline_bolt</md-icon>
            <span class="md-list-item-text">ESMP compliance</span>
          </md-list-item>
          <md-list-item to="/grievances" @click="toggleMenu">
            <md-icon class="md-primary">record_voice_over</md-icon>
            <span class="md-list-item-text">!Tracker portfolio</span>
          </md-list-item>
          <md-divider class="md-inset"></md-divider>
          <md-subheader>Component II</md-subheader>
          <md-divider class="md-inset"></md-divider>
          <md-subheader>Component III</md-subheader>
          <md-divider></md-divider>
          <md-subheader>Technical support</md-subheader>
          <md-list-item>
          <md-icon>language</md-icon>
          <div class="md-list-item-text">
            <span>D4D team</span>
            <span>www.sahamiye.xyz</span>
          </div>
          </md-list-item>
          <md-divider class="md-inset"></md-divider>
          <md-list-item to="/biyoolemis" @click="toggleMenu">
            <md-icon>help_outline</md-icon>
            <span class="md-list-item-text">About this system</span>
          </md-list-item>
        </md-list>
      </md-app-drawer> <!-- end navigation drawer panel -->

      <md-app-content>
        <!-- md-app-content:
        Here we will add any content for our page
        -->
        <progress-bar class="progress-bar" v-if="this.$store.getters.isLoading">

        </progress-bar>
        <router-view></router-view>
      </md-app-content>
    </md-app> <!-- end API md-app -->

  </div>
</template>

<script>
import ProgressBar from './ProgressBar.vue';

export default {
  name: 'Navigation',
  components: {
    ProgressBar,
  },
  data() {
    return {
      menuVisible: false,
      submenuVisible: false,
      title: this.$route.name,
    };
  },
  watch: {
    $route(to) {
      this.title = to.name;
    },
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;

      if (this.title !== this.$route.name) {
        this.title = this.$route.name;
      }
    },
    toggleSubmenu() {
      this.submenuVisible = !this.submenuVisible;
    },
    async logout() {
      this.$store.commit('setLoading', true);
      this.menuVisible = false;
      await this.$store.dispatch('logout');
      this.$store.dispatch('resetState');
      this.$router.replace('/login');
      this.$store.commit('setLoading', false);
    },
  },
};
</script>

<style scoped>
.md-drawer {
  max-width: 220px;
}
</style>
