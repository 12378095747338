<template>
  <div>
    <p class="md-body-2" style="text-align:left;">
        <b>Financial Management</b>
        <br>Funds are disbursed to federal member states, and the FGS, against budgets.
        This module reports on the expenditure, committments and amounts under
        procurement. A summary is provided for <b>Total budget versus Disbursement, Total budget
        versus Expenditure, Disbursement versus Expenditure, Committments versus
        amounts under procurement.</b>
    </p>
      <md-table v-model='searched' md-sort='name' md-sort-order='asc' md-card md-fixed-header>
      <md-table-toolbar>
       <div class='md-toolbar-section-start'>
          <md-dialog :md-active.sync="showDialog">
            <md-dialog-title>Budgets and Expenditure</md-dialog-title>
              <md-tabs md-dynamic-height>
                <md-tab md-label="Expenditure" md-icon="monetization_on">
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Ullam mollitia dolorum dolores quae commodi impedit possimus qui,
                  atque at voluptates cupiditate. Neque quae culpa suscipit praesentium
                  inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                </md-tab>
                <md-tab md-label="Budgets" md-icon="request_quote">
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Ullam mollitia dolorum dolores quae commodi impedit possimus qui,
                  atque at voluptates cupiditate. Neque quae culpa suscipit praesentium
                  inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                </md-tab>
              </md-tabs>
              <md-dialog-actions>
                <md-button class="md-primary" @click="showDialog = false">Close</md-button>
                <md-button class="md-primary" @click="showDialog = false">Save</md-button>
              </md-dialog-actions>
            </md-dialog>
          <md-button class="md-primary md-raised" @click="showDialog = true">Disbursement</md-button>
          <md-button class="md-primary md-raised" @click="showDialog = fasle">Expenditure</md-button>
        </div>
        <md-field md-clearable class='md-toolbar-section-end'>
          <md-input placeholder='Search record...' v-model='search' @input='searchOnTable' />
        </md-field>
      </md-table-toolbar>
      <md-table-empty-state
        md-label='No Record found'
        :md-description='`No record found. Try a different search term or add a new record`'>
        <md-button class="md-primary md-raised" @click="showDialog = false">Add Record</md-button>
      </md-table-empty-state>
      <md-table-row slot='md-table-row' slot-scope='{ item }'>
        <md-table-cell md-label='Submission date' md-sort-by='date'>{{ item.date }}</md-table-cell>
        <md-table-cell md-label='Activity name' md-sort-by='name'>{{ item.name }}</md-table-cell>
        <md-table-cell md-label='Site' md-sort-by='site'>{{ item.site }}</md-table-cell>
        <md-table-cell md-label='Expenditure' md-sort-by='expenditure'>{{ item.expenditure }}</md-table-cell>
        <md-table-cell md-label='Budget' md-sort-by='budget'>{{ item.budget }}</md-table-cell>
        <md-table-cell md-label='Actions'>
          <md-button class="md-icon-button md-raised md-dense md-primary">
            <md-icon>dehaze</md-icon>
          </md-button>
          <md-button class="md-icon-button md-raised md-dense md-primary">
            <md-icon>paid</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
</div>
</template>

<script>
const toLower = (text) => text.toString().toLowerCase();

const searchByName = (items, term) => {
  if (term) {
    return items.filter((item) => toLower(item.name).includes(toLower(term)));
  }

  return items;
};

export default {
  name: 'TableSearch',
  data: () => ({
    search: null,
    showDialog: false,
    searched: [],
    Expenditures: [],
  }),
  methods: {
    searchOnTable() {
      this.searched = searchByName(this.Expenditures, this.search);
    },
  },
  created() {
    this.searched = this.Expenditures;
  },
};
</script>

<style lang="scss" scoped>
.md-table + .md-table {
    margin-top: 16px
  }
.md-field {
    max-wdateth: 300px;
  }
  .md-table-head {
  text-align: center;
}
  .md-table-cell {
  text-align: left;
}
.container {
  display: flex;
  align-items: left;
}
</style>
