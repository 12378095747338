import Vue from 'vue';
import Vuex from 'vuex';
import VueAxios from 'vue-axios';
import Toasted from 'vue-toasted';
import VueCookies from 'vue-cookies';
import VueMaterial from 'vue-material';
import VueApexCharts from 'vue-apexcharts';
import Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
import Maps from 'highcharts/modules/map';
import HighchartsVue from 'highcharts-vue';
import Annotation from 'highcharts/modules/annotations';
import Solidgauge from 'highcharts/modules/solid-gauge';
import exportingInit from 'highcharts/modules/exporting';
import stockInit from 'highcharts/modules/stock';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import VueSimpleAlert from 'vue-simple-alert';
import VTooltip from 'v-tooltip';

import VueGridLayout from 'vue-grid-layout';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from './plugins/axios';
import Navigation from './components/Navigation.vue';
import './assets/theme.scss';

Maps(Highcharts);
more(Highcharts);
Annotation(Highcharts);
Solidgauge(Highcharts);
stockInit(Highcharts);
exportingInit(Highcharts);

Vue.config.productionTip = false;
Vue.use(VTooltip);
Vue.use(Vuex);
Vue.use(VueAxios, axios);
Vue.use(VueApexCharts);
Vue.use(HighchartsVue);
Vue.use(VueMaterial);
Vue.use(VueGridLayout);
Vue.use(VueCookies);
Vue.use(VueSimpleAlert, { reverseButtons: true });
Vue.use(Toasted, {
  router,
  duration: 5000,
  type: 'success',
});
Vue.component('navigation', Navigation);
Vue.component('apexchart', VueApexCharts);
Vue.component('highchart', HighchartsVue);

// eslint-disable-next-line
new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
});
