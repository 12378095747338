<template>
  <div>
    <p class="md-body-2" style="text-align:left;">
        <b>Project Notifications</b>
        <br>There are critical processes that require <b>approval or review</b>,
        before they are completed.
        This section tracks the status of <b>pending actions</b>
         during project implementation.
    </p>
      <md-table v-model='searched' md-sort='process' md-sort-order='asc' md-card md-fixed-header>
      <md-table-toolbar>
        <div class='md-toolbar-section-start'>
          <md-dialog :md-active.sync="showDialog">
            <md-dialog-title>Pending Actions</md-dialog-title>
              <md-tabs md-dynamic-height>
                <md-tab md-label="New Action" md-icon="support_agent">
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Ullam mollitia dolorum dolores quae commodi impedit possimus qui,
                  atque at voluptates cupiditate. Neque quae culpa suscipit praesentium
                  inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                </md-tab>
                <md-tab md-label="Followup Action" md-icon="highlight">
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Ullam mollitia dolorum dolores quae commodi impedit possimus qui,
                  atque at voluptates cupiditate. Neque quae culpa suscipit praesentium
                  inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam mollitia dolorum
                  dolores quae commodi impedit possimus qui, atque at voluptates cupiditate. Neque
                    quae culpa suscipit praesentium inventore ducimus ipsa aut.</p>
                </md-tab>
              </md-tabs>
              <md-dialog-actions>
                <md-button class="md-primary" @click="showDialog = false">Close</md-button>
                <md-button class="md-primary" @click="showDialog = false">Save</md-button>
              </md-dialog-actions>
            </md-dialog>
          <md-button class="md-primary md-raised" @click="showDialog = false">Submit Action</md-button>
        </div>
        <md-field md-clearable class='md-toolbar-section-end'>
          <md-input placeholder='Search record...' v-model='search' @input='searchOnTable' />
        </md-field>
      </md-table-toolbar>
      <md-table-empty-state
        md-label='No record found'
        :md-description='`No record found. Try a different search term or create a new action`'>
         <md-button class="md-primary md-raised" @click="showDialog = false">Submit Action</md-button>
      </md-table-empty-state>
      <md-table-row slot='md-table-row' slot-scope='{ item }'>
        <md-table-cell md-label='Submission date' md-sort-by='date'>{{ item.date }}</md-table-cell>
        <md-table-cell md-label='Submitted by' md-sort-by='submitted_by'>{{ item.submitted_by }}</md-table-cell>
        <md-table-cell md-label='Submitted to' md-sort-by='submitted_to'>{{ item.submitted_to }}</md-table-cell>
        <md-table-cell md-label='Action required' md-sort-by='process'>{{ item.process }}</md-table-cell>
        <md-table-cell md-label='Action status' md-sort-by='action_type'>{{ item.action_type }}</md-table-cell>
        <md-table-cell md-label='Actions'>
          <md-button class="md-icon-button md-raised md-dense md-primary">
            <md-icon>visibility</md-icon>
          </md-button>
          <md-button class="md-icon-button md-raised md-dense md-primary">
            <md-icon>outgoing_mail</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
</div>
</template>

<script>
const toLower = (text) => text.toString().toLowerCase();

const searchByprocess = (items, term) => {
  if (term) {
    return items.filter((item) => toLower(item.process).includes(toLower(term)));
  }

  return items;
};

export default {
  process: 'TableSearch',
  data: () => ({
    search: null,
    showDialog: false,
    searched: [],
    reports: [],
  }),
  methods: {
    searchOnTable() {
      this.searched = searchByprocess(this.reports, this.search);
    },
  },
  created() {
    this.searched = this.reports;
  },
};
</script>

<style lang="scss" scoped>
.md-table + .md-table {
    margin-top: 16px
  }
.md-field {
    max-wdateth: 300px;
  }
  .md-table-head {
  text-align: center;
}
  .md-table-cell {
  text-align: left;
}
.container {
  display: flex;
  align-items: left;
}
</style>
